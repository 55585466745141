import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
  UseAutocompleteProps,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { useDebounceTyping } from "./layout/Topbar/GlobalSearch";

import Customer from "@/modules/customer/Customer";

export type CustomerOptions = { value: string; label: string };
export type CustomersSelectFieldProps = Pick<
  UseAutocompleteProps<CustomerOptions, false, false, false>,
  "onChange"
> & { label?: string; placeholder?: string };
const CustomersSelectField = ({
  label,
  placeholder,
  ...props
}: CustomersSelectFieldProps) => {
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounceTyping(search, 500);

  const { data, isFetching } = useQuery({
    queryKey: ["customer", debouncedSearch],
    queryFn: () => Customer.getCustomers(search),
  });

  const customerOptions = (data ?? []).map((c) => ({
    value: c.id,
    label: c.name,
  }));

  return (
    <Autocomplete
      fullWidth
      sx={{ maxWidth: 300 }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label ?? "Customer"}
            placeholder={placeholder ?? "Select the customer"}
            slotProps={{
              input: {
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {isFetching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </InputAdornment>
                ),
              },
            }}
          />
        );
      }}
      options={customerOptions ?? []}
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      onInputChange={(_, newInputValue) => {
        setSearch(newInputValue);
      }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option?.label
      }
      isOptionEqualToValue={(
        option: CustomerOptions,
        value: CustomerOptions
      ) => {
        return option.value === value.value;
      }}
      loading={isFetching}
      size="small"
      {...props}
    />
  );
};
export default CustomersSelectField;
