import { CreateAUserSchemaType, EditAUserSchemaType } from "./schemas";

import HttpClient from "@/modules/common/lib/httpClient";
import { NewPasswordSchemaType } from "@/modules/common/schemas";
import {
  ApiPagination,
  ApiPaginationResponse,
  ApiQuery,
  WithId,
} from "@/modules/common/types/sharedTypes";

export type AUserDetails = WithId<{
  name: string;
  email: string;
  active: boolean;
  superuser: boolean;
  can_modify: boolean;
  template?: {
    id: string;
    name: string;
  };
  timezone?: string;
  last?: string;
  home?: string;
}>;

export type AUsersItem = Pick<
  AUserDetails,
  "id" | "name" | "email" | "template" | "active" | "last"
>;
export type AUsersList = ApiPaginationResponse<AUsersItem>;
export type AUserCreateParams = CreateAUserSchemaType;
export type AUserEditParams = EditAUserSchemaType;
export type ChangePasswordResponse = { has_success: boolean };

export type AusersListDetails = Pick<AUsersItem, "id" | "name" | "email">;

export type AuserTemplateDetails = WithId<{
  name: string;
  roles: string[];
  ausers: AusersListDetails[];
}>;
export type AuserTemplatesItem = Pick<AuserTemplateDetails, "id" | "name"> & {
  roles_count: number;
  ausers_count: number;
};
export type TemplatesList = ApiPaginationResponse<AuserTemplatesItem>;
export type TemplateCreateParams = {
  name: string;
  roles: string[];
};
export type TemplateEditParams = WithId<TemplateCreateParams>;
export type AuserPasswordChangePrams = WithId<NewPasswordSchemaType>;

const getList = async (
  params: ApiPagination & ApiQuery & { active?: boolean }
): Promise<AUsersList> => {
  return HttpClient.get(`/ausers`, params);
};

const getOne = async (id: string): Promise<AUserDetails> => {
  return HttpClient.get(`/ausers/${id}`);
};

const getAUsers = async (search: string): Promise<AUsersItem[]> => {
  return HttpClient.get(`/ausers/filters-search`, {
    q: search,
  });
};

const create = async (params: AUserCreateParams): Promise<AUserDetails> => {
  return HttpClient.post(`/ausers`, params);
};

const edit = async (params: AUserEditParams): Promise<AUserDetails> => {
  return HttpClient.patch(`/ausers/${params.id}`, params);
};

const getTemplates = async (
  params: ApiPagination & ApiQuery
): Promise<TemplatesList> => {
  return HttpClient.get(`/ausers/templates`, params);
};

const getTemplate = async (id: string): Promise<AuserTemplateDetails> => {
  return HttpClient.get(`/ausers/templates/${id}`);
};

const getAllowedRoles = async (): Promise<string[]> => {
  return HttpClient.get(`/ausers/allowed_roles`);
};

const createTemplate = async (
  template: TemplateCreateParams
): Promise<AuserTemplateDetails> => {
  return HttpClient.post(`/ausers/templates`, template);
};

const editTemplate = async ({
  id,
  ...template
}: TemplateEditParams): Promise<AuserTemplateDetails> => {
  return HttpClient.patch(`/ausers/templates/${id}`, template);
};

const changePassword = async ({
  id,
  ...data
}: AuserPasswordChangePrams): Promise<void> => {
  return HttpClient.put(`/ausers/${id}/password`, data);
};

const resetTOTP = async (id: string): Promise<void> => {
  return HttpClient.delete(`/ausers/${id}/totp`);
};

export default {
  getOne,
  getList,
  getAUsers,
  create,
  edit,
  changePassword,
  getAllowedRoles,
  getTemplate,
  getTemplates,
  createTemplate,
  editTemplate,
  resetTOTP,
};
