import HttpClient from "../common/lib/httpClient";
import { ApiPagination, ApiQuery } from "../common/types/sharedTypes";

import { QREList } from "./QRE.types";

const getList = async (
  params: ApiPagination & ApiQuery & { creator?: string; status?: string }
): Promise<QREList> => {
  return HttpClient.get(`/qre`, params);
};

export default {
  getList,
};
