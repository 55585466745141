import { Route, Routes } from "react-router";

import AuthorizedElement from "../auth/AuthorizedElement";
import { useAdm4Rollback } from "../auth/useAdm4Rollback";

import QREList from "./pages/QREList";

import GoToAdm4 from "@/router/GoToAdm4";

const QRERoutes = () => {
  useAdm4Rollback("qre");
  return (
    <Routes>
      <Route
        index
        element={
          <AuthorizedElement requiredRoles="qre::list" element={<QREList />} />
        }
      />
      <Route path="*" element={<GoToAdm4 />} />
    </Routes>
  );
};

export default QRERoutes;
