import { NewPasswordSchemaType } from "../common/schemas";

import HttpClient from "@/modules/common/lib/httpClient";

export type ACL =
  | "agents"
  | "agents>list"
  | "agents>statistics"
  | "analytics"
  | "analytics>aac_efficiency"
  | "analytics>answers"
  | "analytics>blocked-opiners"
  | "analytics>cashflow"
  | "analytics>expenses"
  | "analytics>external"
  | "analytics>dashboards"
  | "analytics>dashboards>flex"
  | "analytics>heatmap"
  | "analytics>map"
  | "analytics>monitoring-agents-vrf"
  | "analytics>overview"
  | "analytics>users-on-latest"
  | "analytics>users-per-region"
  | "answers"
  | "answers>list"
  | "answers>queue"
  | "answers>approval"
  | "ausers"
  | "ausers>list"
  | "ausers>templates>list"
  | "beacons"
  | "beacons>create"
  | "beacons>list"
  | "cashflow"
  | "ceu"
  | "comm"
  | "comm>email"
  | "comm>email>create"
  | "comm>email>list"
  | "comm>email>templates"
  | "comm>push"
  | "comm>push-to-dev"
  | "comm>push>create"
  | "comm>push>list"
  | "courier"
  | "customers"
  | "dashboard"
  | "faq"
  | "faq>tags"
  | "faq>articles"
  | "faq>categories"
  | "locations"
  | "locations>regions"
  | "locations>explorer"
  | "merges"
  | "missions"
  | "missions>create"
  | "missions>create>explorer"
  | "missions>create>geo"
  | "missions>create>virtual"
  | "missions>list"
  | "qre"
  | "polygons"
  | "projects"
  | "projects>create"
  | "projects>create>audit"
  | "projects>create>other"
  | "projects>create>tree"
  | "projects>list"
  | "sac"
  | "bespoke"
  | "bespoke>uber>pricing"
  | "bespoke>uber>pricing>routes"
  | "bespoke>uber>pricing>review"
  | "bespoke>uber>pricing>batches"
  | "bespoke>uber>pricing>dashboard"
  | "bespoke>uber>structural"
  | "bespoke>uber>structural>answers"
  | "support"
  | "tasks"
  | "users"
  | "users>active_users"
  | "users>activity"
  | "users>balance"
  | "users>filters"
  | "users>frauds"
  | "users>list"
  | "users>queue";

export type ALLOWED_ROLES =
  | "dashboard::view"
  | "analytics::dashboards::flex"
  | "answers_queues::agent_access"
  | "answers::detail"
  | "ausers::list"
  | "ausers::create"
  | "ausers::update"
  | "ausers::detail"
  | "ausers::reset_totp"
  | "ausers::templates::list"
  | "ausers::templates::create"
  | "ausers::templates::update"
  | "ausers::password::update"
  | "beacons::list"
  | "beacons::create"
  | "beacons::detail"
  | "customers::list"
  | "customers::create"
  | "customers::detail"
  | "customers::update"
  | "customers::set_ccpm"
  | "customers::cacusers::list"
  | "customers::cacusers::create"
  | "customers::cacusers::update"
  | "customers::cacfilters::list"
  | "customers::cacfilters::create"
  | "customers::cacfilters::update"
  | "customers::cacfilters::delete"
  | "customers::beacon_tags::create"
  | "customers::beacon_tags::update"
  | "customers::beacon_tags::list"
  | "customers::sponsorships::list"
  | "customers::sponsorships::create"
  | "customers::sponsorships::detail"
  | "faq::articles::list"
  | "faq::articles::create"
  | "faq::articles::update"
  | "faq::tags::list"
  | "faq::tags::create"
  | "faq::tags::update"
  | "faq::categories::list"
  | "faq::categories::create"
  | "faq::categories::update"
  | "faq::articles::update"
  | "live::detail"
  | "live::update"
  | "locations::regions::list"
  | "locations::regions::create"
  | "locations::regions::detail"
  | "locations::explorer::list"
  | "locations::explorer::create"
  | "locations::explorer::detail"
  | "merged::list"
  | "merged::detail"
  | "missions::detail"
  | "projects::create"
  | "projects::detail"
  | "projects::list"
  | "projects::update"
  | "projects::audit::create"
  | "projects::audit::detail"
  | "projects::audit::list"
  | "projects::audit::update"
  | "projects::controls::approve"
  | "projects::controls::pause"
  | "projects::controls::play"
  | "projects::controls::stop"
  | "projects::dtree::create"
  | "projects::exports::audit"
  | "projects::exports::include_ids"
  | "projects::exports::views"
  | "projects::exports::xlsx"
  | "qre::list"
  | "qre::detail"
  | "qre::create"
  | "qre::update"
  | "qre::review"
  | "sp::uber::pricing::routes::list"
  | "sp::uber::structural::answers::list"
  | "sp::uber::structural::answers::sync"
  | "sp::uber::pricing::delivery::dashboard"
  | "sp::uber::pricing::batches::list"
  | "support::tickets::list"
  | "support::exports::refunded"
  | "users::detail"
  | "users::list";

export type IMPLEMENTED_MODULES =
  | "answers"
  | "ausers"
  | "customers"
  | "faq"
  | "live"
  | "locations"
  | "merges"
  | "spuber"
  | "flexviews"
  | "users"
  | "projects"
  | "beacons"
  | "qre"
  | ALLOWED_ROLES;

export type LoginParams = {
  email: string;
  password: string;
};

type LoginResponse = {
  auth_token: string;
  token_type: string;
  mfa_type: string;
  mfa_enroll: boolean;
  mfa_secret?: string;
  mfa_uri?: string;
};

export type MfaParams = {
  mfa: string;
};

type AuthResponse = {
  access_token: string;
  token_type: string;
};

export type MeResponse = {
  name: string;
  email: string;
  image?: string;
  home?: string;
  lang: string;
  locale: string;
  timezone: string;
  roles: ALLOWED_ROLES[];
  rollback_modules: IMPLEMENTED_MODULES[];
  menu: ACL[];
};

const login = async (params: LoginParams): Promise<LoginResponse> =>
  HttpClient.post(`/auth/login`, params);

const mfaValidate = async (
  params: MfaParams,
  token: string
): Promise<AuthResponse> =>
  HttpClient.post(`/auth/mfa`, params, {
    headers: {
      Authorization: token,
    },
  });

const logout = async (): Promise<void> => HttpClient.get(`/auth/logout`);

const me = async (): Promise<MeResponse> => HttpClient.get(`/me`);

const changePassword = async (data: NewPasswordSchemaType): Promise<void> =>
  HttpClient.put(`/auth/password`, data);

export default {
  me,
  login,
  mfaValidate,
  logout,
  changePassword,
};
