import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AnimationIcon from "@mui/icons-material/Animation";
import ArticleIcon from "@mui/icons-material/Article";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BatchPredictionOutlinedIcon from "@mui/icons-material/BatchPredictionOutlined";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import CategoryIcon from "@mui/icons-material/Category";
import ChecklistIcon from "@mui/icons-material/Checklist";
import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import DeviceThermostatOutlinedIcon from "@mui/icons-material/DeviceThermostatOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import FeedIcon from "@mui/icons-material/Feed";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FingerprintOutlinedIcon from "@mui/icons-material/FingerprintOutlined";
import FlightIcon from "@mui/icons-material/Flight";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import HelpIcon from "@mui/icons-material/Help";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ImportantDevicesOutlinedIcon from "@mui/icons-material/ImportantDevicesOutlined";
import KeyIcon from "@mui/icons-material/Key";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import ListIcon from "@mui/icons-material/List";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MapIcon from "@mui/icons-material/Map";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import MergeIcon from "@mui/icons-material/Merge";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import NearbyErrorIcon from "@mui/icons-material/NearbyError";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import RateReviewIcon from "@mui/icons-material/RateReview";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import RocketIcon from "@mui/icons-material/Rocket";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import WebStoriesOutlinedIcon from "@mui/icons-material/WebStoriesOutlined";
import WorkIcon from "@mui/icons-material/Work";

import { SidebarLinkProps } from "./SidebarLink";

import { ACL } from "@/modules/auth/Auth";

export type MenuItem = {
  label: string;
  to: string;
  icon: React.ReactElement;
  nested?: Record<ACL, MenuItem> | SidebarLinkProps[];
  selected?: boolean;
};

const MENU_ITEMS: Record<ACL, MenuItem> = {
  dashboard: {
    label: "Dashboard",
    to: "/dashboard",
    icon: <SpeedOutlinedIcon />,
  },
  customers: {
    label: "Customers",
    to: "/customers",
    icon: <PersonIcon />,
  },
  sac: {
    label: "Missions (sac)",
    to: "/sac",
    icon: <FlightIcon />,
  },
  missions: {
    label: "Missions",
    icon: <RocketIcon />,
    to: "/missions",
    nested: {
      "missions>list": {
        label: "List",
        to: "/missions",
        icon: <ListIcon />,
      },
      "missions>create": {
        label: "Create",
        to: "/missions/new",
        icon: <AddCircleOutlineIcon />,
        nested: {
          "missions>create>virtual": {
            label: "Virtual",
            to: "/missions/new/virtual",
            icon: <PhoneIphoneIcon />,
          },
          "missions>create>geo": {
            label: "Geo",
            to: "/missions/new/geo",
            icon: <PlaceOutlinedIcon />,
          },
          "missions>create>explorer": {
            label: "Explorer",
            to: "/missions/new/explorer",
            icon: <MapIcon />,
          },
        },
      },
    },
  },
  projects: {
    label: "Projects",
    to: "/projects",
    icon: <WorkIcon />,
    nested: {
      "projects>list": {
        label: "List",
        to: "/projects",
        icon: <ListIcon />,
      },
      "projects>create": {
        label: "Create",
        to: "/projects/audit",
        icon: <AddCircleOutlineIcon />,
        nested: {
          "projects>create>audit": {
            label: "Audit",
            to: "/audit/new",
            icon: <BeenhereOutlinedIcon />,
          },
          "projects>create>tree": {
            label: "Decision Tree",
            to: "/projects/tree",
            icon: <AccountTreeOutlinedIcon />,
          },
          "projects>create>other": {
            label: "Other",
            to: "/projects/new",
            icon: <WorkIcon />,
          },
        },
      },
    },
  },
  agents: {
    label: "Agents",
    to: "/agents",
    icon: <HowToRegIcon />,
    nested: {
      "agents>list": {
        label: "List",
        to: "/agents",
        icon: <ListIcon />,
      },
      "agents>statistics": {
        label: "Statistics",
        to: "/agents/statistics",
        icon: <ShowChartOutlinedIcon />,
      },
    },
  },
  bespoke: {
    label: "Bespoke Solutions",
    to: "/",
    icon: <AnimationIcon />,
    nested: {
      "bespoke>uber>pricing": {
        label: "Uber Pricing",
        to: "/",
        icon: <PriceCheckIcon />,
        nested: {
          "bespoke>uber>pricing>routes": {
            label: "Routes",
            to: "/spuber/routes",
            icon: <RouteOutlinedIcon />,
          },
          "bespoke>uber>pricing>review": {
            label: "Reviews",
            to: "/spuber/routes/review",
            icon: <RateReviewIcon />,
          },
          "bespoke>uber>pricing>batches": {
            label: "Batches",
            to: "/spuber/batches",
            icon: <BatchPredictionOutlinedIcon />,
          },
          "bespoke>uber>pricing>dashboard": {
            label: "Dashboard",
            to: "/spuber/delivery/dashboard",
            icon: <CalendarViewWeekIcon />,
          },
        },
      },
      "bespoke>uber>structural": {
        label: "Uber Structural",
        to: "/",
        icon: <AccountTreeIcon />,
        nested: {
          "bespoke>uber>structural>answers": {
            label: "Synchronizer",
            to: "/spuber/structural",
            icon: <ChecklistIcon />,
          },
        },
      },
    },
  },
  courier: {
    label: "Courier",
    to: "/courier/simulator",
    icon: <LocalShippingIcon />,
  },
  locations: {
    label: "Locations",
    to: "/locations",
    icon: <LocationOnIcon />,
    nested: {
      "locations>regions": {
        label: "Regions",
        to: "/locations/regions",
        icon: <EditLocationAltIcon />,
      },
      "locations>explorer": {
        label: "Explorer Polygons",
        to: "/locations/explorer",
        icon: <FormatShapesIcon />,
      },
    },
  },
  answers: {
    label: "Answers",
    to: "/answers",
    icon: <QuestionAnswerIcon />,
    nested: {
      "answers>list": {
        label: "List",
        to: "/answers",
        icon: <ListIcon />,
      },
      "answers>queue": {
        label: "Queue",
        to: "/answers/queue",
        icon: <WebStoriesOutlinedIcon />,
      },
      "answers>approval": {
        label: "Approval",
        to: "/answers/approval",
        icon: <WebStoriesOutlinedIcon />,
      },
    },
  },
  merges: {
    label: "Merges",
    to: "/merges",
    icon: <MergeIcon />,
  },
  qre: {
    label: "QREs",
    to: "/qre",
    icon: <FeedIcon />,
  },
  beacons: {
    label: "Beacons",
    to: "/beacons",
    icon: <LightbulbIcon />,
    nested: {
      "beacons>list": {
        label: "List",
        to: "/beacons",
        icon: <ListIcon />,
      },
      "beacons>create": {
        label: "Create",
        to: "/beacons/new",
        icon: <AddCircleOutlineIcon />,
      },
    },
  },
  users: {
    label: "Opiners",
    to: "/users",
    icon: <PersonPinCircleIcon />,
    nested: {
      "users>list": {
        label: "List",
        to: "/users",
        icon: <ListIcon />,
      },
      "users>frauds": {
        label: "Frauds",
        to: "/users/frauds",
        icon: <NearbyErrorIcon />,
      },
      "users>queue": {
        label: "VRFDocs Queue",
        to: "/users/queue",
        icon: <FingerprintOutlinedIcon />,
      },
      "users>filters": {
        label: "Filter Simulator",
        to: "/users/filters",
        icon: <FilterAltOutlinedIcon />,
      },
      "users>active_users": {
        label: "Active Users",
        to: "/users/active_users",
        icon: <RecordVoiceOverOutlinedIcon />,
      },
      "users>balance": {
        label: "Balance",
        to: "/users/balance",
        icon: <AccountBalanceWalletOutlinedIcon />,
      },
      "users>activity": {
        label: "Activity",
        to: "/users/activity",
        icon: <AccessTimeOutlinedIcon />,
      },
    },
  },
  cashflow: {
    label: "Cashflow",
    to: "/cash",
    icon: <MonetizationOnIcon />,
  },
  ceu: {
    label: "CEU",
    to: "/ceu",
    icon: <SensorOccupiedIcon />,
  },
  comm: {
    label: "Comm Center",
    to: "/comm",
    icon: <PermPhoneMsgIcon />,
    nested: {
      "comm>push-to-dev": {
        label: "Push to dev devices",
        to: "/dev/push",
        icon: <ImportantDevicesOutlinedIcon />,
      },
      "comm>push": {
        label: "Push",
        to: "/comm/push",
        icon: <NotificationsActiveOutlinedIcon />,
        nested: {
          "comm>push>list": {
            label: "List",
            to: "/comm/push",
            icon: <ListIcon />,
          },
          "comm>push>create": {
            label: "Create",
            to: "/comm/new_push",
            icon: <AddCircleOutlineIcon />,
          },
        },
      },
      "comm>email": {
        label: "Email",
        to: "/comm/email",
        icon: <MarkEmailUnreadOutlinedIcon />,
        nested: {
          "comm>email>list": {
            label: "List",
            to: "/comm/email",
            icon: <ListIcon />,
          },
          "comm>email>create": {
            label: "Create",
            to: "/comm/new_email",
            icon: <AddCircleOutlineIcon />,
          },
          "comm>email>templates": {
            label: "Templates",
            to: "/comm/templates",
            icon: <CopyAllOutlinedIcon />,
          },
        },
      },
    },
  },
  analytics: {
    label: "Analytics",
    to: "/analytics",
    icon: <AnalyticsIcon />,
    nested: {
      "analytics>overview": {
        label: "Overview",
        to: "/analytics",
        icon: <QueryStatsOutlinedIcon />,
      },
      "analytics>dashboards": {
        label: "Dashboards",
        to: "/",
        icon: <AccountTreeOutlinedIcon />,
        nested: {
          "analytics>dashboards>flex": {
            label: "Flexviews",
            to: "/analytics/flex",
            icon: <QueryStatsOutlinedIcon />,
          },
        },
      },
      "analytics>map": {
        label: "Map",
        to: "/map",
        icon: <PublicOutlinedIcon />,
      },
      "analytics>heatmap": {
        label: "Heat Map",
        to: "/analytics/heatmap",
        icon: <DeviceThermostatOutlinedIcon />,
      },
      "analytics>users-per-region": {
        label: "Users per Region",
        to: "/dx/user_region",
        icon: <PersonPinCircleOutlinedIcon />,
      },
      "analytics>users-on-latest": {
        label: "Users on Latest",
        to: "/dx/users_latest",
        icon: <UpdateOutlinedIcon />,
      },
      "analytics>answers": {
        label: "Answers",
        to: "/analytics/answers",
        icon: <QuestionAnswerOutlinedIcon />,
      },
      "analytics>blocked-opiners": {
        label: "Blocked Opiners",
        to: "/analytics/blocked_users",
        icon: <PersonOffOutlinedIcon />,
      },
      "analytics>monitoring-agents-vrf": {
        label: "Monitoring Agents VRF",
        to: "/analytics/monitoring_agents_vrf",
        icon: <DocumentScannerOutlinedIcon />,
      },
      "analytics>cashflow": {
        label: "Cashflow",
        to: "/analytics/cahflow",
        icon: <MonetizationOnOutlinedIcon />,
      },
      "analytics>expenses": {
        label: "Expenses",
        to: "/analytics/expenses",
        icon: <RequestQuoteOutlinedIcon />,
      },
      "analytics>aac_efficiency": {
        label: "AAC Efficiency",
        to: "/analytics/aac_efficiency",
        icon: <RequestQuoteOutlinedIcon />,
      },
      "analytics>external": {
        label: "External",
        to: "/analytics/external",
        icon: <LaunchOutlinedIcon />,
        nested: [
          {
            label: "Apple iTunes Analytics",
            to: "https://analytics.itunes.apple.com/#/overview?app=591534544&interval=r&datesel=d30&pmeasure=units&tmeasure=units",
            icon: <LinkOutlinedIcon />,
            target: "_blank",
            rel: "noopener noreferrer",
          },
          {
            label: "Google Play Store Analytics",
            to: "https://play.google.com/apps/publish/?dev_acc=09653991225711056942#StatsPlace:statm=CURRENT_DEVICE_INSTALLS&statd=OS_VERSION&p=br.com.pinion",
            icon: <LinkOutlinedIcon />,
            target: "_blank",
            rel: "noopener noreferrer",
          },
          {
            label: "Google Play - User Acquisition",
            to: "https://play.google.com/apps/publish/?dev_acc=09653991225711056942#AcquisitionPerformancePlace:p=br.com.pinion",
            icon: <LinkOutlinedIcon />,
            target: "_blank",
            rel: "noopener noreferrer",
          },
          {
            label: "Google Play - Crashes",
            to: "https://play.google.com/apps/publish/?dev_acc=09653991225711056942#ErrorClusterListPlace:p=br.com.pinion&et=CRASH&sh=false&lr=LAST_7_DAYS&av=2.0.1",
            icon: <LinkOutlinedIcon />,
          },
          {
            label: "App Annie",
            to: "https://www.appannie.com/comparator/?_ref=header&breakdown=product&chart_type=downloads&countries=ALL&start_date=2016-05-21&end_date=2016-06-19",
            icon: <LinkOutlinedIcon />,
            target: "_blank",
            rel: "noopener noreferrer",
          },
          {
            label: "Facebook App Analytics",
            to: "https://www.facebook.com/analytics/390335034366372/",
            icon: <LinkOutlinedIcon />,
            target: "_blank",
            rel: "noopener noreferrer",
          },
          {
            label: "Appcelerator",
            to: "https://platform.appcelerator.com/#/app/55ef5289229e897037881fe4",
            icon: <LinkOutlinedIcon />,
            target: "_blank",
            rel: "noopener noreferrer",
          },
          {
            label: "New Relic",
            to: "https://rpm.newrelic.com/accounts/608917/applications",
            icon: <LinkOutlinedIcon />,
            target: "_blank",
            rel: "noopener noreferrer",
          },
        ],
      },
    },
  },
  ausers: {
    label: "Permissions",
    icon: <KeyIcon />,
    to: "/ausers",
    nested: {
      "ausers>list": {
        label: "Admin Users",
        to: "/ausers",
        icon: <ManageAccountsIcon />,
      },
      "ausers>templates>list": {
        label: "Templates",
        to: "/ausers/templates",
        icon: <KeyIcon />,
      },
    },
  },
  faq: {
    label: "FAQ",
    to: "/faq",
    icon: <HelpIcon />,
    nested: {
      "faq>articles": {
        label: "Articles",
        to: "/faq/articles",
        icon: <ArticleIcon />,
      },
      "faq>tags": {
        label: "Tags",
        to: "/faq/tags",
        icon: <LocalOfferIcon />,
      },
      "faq>categories": {
        label: "Categories",
        to: "/faq/categories",
        icon: <CategoryIcon />,
      },
    },
  },
  tasks: {
    label: "Tasks",
    to: "/tasks",
    icon: <AssignmentTurnedInIcon />,
  },
  support: {
    label: "Support",
    to: "/support/tickets",
    icon: <SupportAgentOutlinedIcon />,
  },
} as Record<ACL, MenuItem>;

function getMenuItem(
  menuItem: Record<ACL, MenuItem> | SidebarLinkProps[],
  permissions: ACL[]
): SidebarLinkProps[] {
  if (Array.isArray(menuItem)) {
    return menuItem;
  }

  const menuItems: SidebarLinkProps[] = [];

  (Object.keys(menuItem) as ACL[]).forEach((acl) => {
    if (!permissions.includes(acl)) {
      return;
    }

    const item: SidebarLinkProps = {
      label: menuItem[acl].label,
      to: menuItem[acl].to,
      icon: menuItem[acl].icon || <span />,
      selected: menuItem[acl].selected,
    };

    const nested = menuItem[acl].nested;
    if (nested) {
      item["nested"] = getMenuItem(nested, permissions);
    }

    menuItems.push(item);
  });

  return menuItems;
}

interface NestedItem {
  label?: string;
  to?: string;
  icon?: JSX.Element | null;
  nested?: Record<string, NestedItem> | NestedItem[];
}

export interface FlattenedItem {
  searchable?: string;
  label: string;
  type: "Pages" | "Global Search" | string;
  to?: string;
  search?: string;
  disabled?: boolean;
  icon: JSX.Element | null;
}

function flatmapHelper(
  obj: Record<string, NestedItem>,
  permissions: string[]
): FlattenedItem[] {
  function flatten(
    obj: Record<string, NestedItem> | NestedItem[],
    parentLabel = ""
  ): FlattenedItem[] {
    const result: FlattenedItem[] = [];

    Object.keys(obj).forEach((key) => {
      const isArrayChild = !isNaN(parseFloat(key));
      if (isArrayChild || !permissions.includes(key)) {
        return;
      }
      const item: NestedItem = (obj as Record<string, NestedItem>)[key];
      const newLabel = parentLabel
        ? `${parentLabel} > ${item.label}`
        : item.label || key.replace(/^[a-z]/, (c) => c.toUpperCase());

      if (item.nested) {
        result.push(...flatten(item.nested, newLabel));
      } else {
        key = key.split(">").reverse().join(" ");

        const newItem: FlattenedItem = {
          searchable: `${item.to?.substring(1)} ${key}`,
          label: newLabel.trim(),
          to: item.to || "",
          type: "Pages",
          icon: item.icon || null,
        };
        result.push(newItem);
      }
    });

    return result;
  }

  return flatten(obj);
}

export const getMenuForSearch = (permissions: ACL[]) =>
  flatmapHelper(MENU_ITEMS, permissions);

export default function getMenu(permissions: ACL[]): SidebarLinkProps[] {
  return getMenuItem(MENU_ITEMS, permissions);
}
