import { Link as MLink, LinkProps } from "@mui/material";
import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router";

type Props = LinkProps & RouterLinkProps;
const Link = (props: Props, ref: unknown) => {
  return (
    <MLink
      ref={ref}
      component={RouterLink}
      color="info.light"
      sx={{ "&:hover": { textDecoration: "underline" } }}
      {...props}
    />
  );
};
export default React.forwardRef<unknown, Props>(Link);
