import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
  UseAutocompleteProps,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { forwardRef, useState } from "react";

import { useDebounceTyping } from "./layout/Topbar/GlobalSearch";

import AUser from "@/modules/auser/AUser";

export type AUserOptions = { value: string; label: string };
export type AUsersSelectFieldProps = Pick<
  UseAutocompleteProps<AUserOptions, false, false, false>,
  "onChange"
> & { label?: string; placeholder?: string };

const AUsersSelectField = forwardRef<HTMLDivElement, AUsersSelectFieldProps>(
  function AUsersSelectField({ label, placeholder, ...props }, ref) {
    const [search, setSearch] = useState("");
    const [debouncedSearch] = useDebounceTyping(search, 500);

    const { data, isFetching } = useQuery({
      queryKey: ["auser", debouncedSearch],
      queryFn: () => AUser.getAUsers(search),
    });

    const auserOptions = (data ?? []).map((c) => ({
      value: c.id,
      label: c.name,
    }));

    return (
      <Autocomplete
        ref={ref}
        fullWidth
        sx={{ maxWidth: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ?? "AUser"}
            placeholder={placeholder ?? "Select the user"}
            slotProps={{
              input: {
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {isFetching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
        options={auserOptions ?? []}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )}
        onInputChange={(_, newInputValue) => {
          setSearch(newInputValue);
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.label
        }
        isOptionEqualToValue={(option: AUserOptions, value: AUserOptions) => {
          return option.value === value.value;
        }}
        loading={isFetching}
        size="small"
        {...props}
      />
    );
  }
);
export default AUsersSelectField;
